import Parser from "rss-parser"
import {useState, useEffect} from "react"
import PostItem from "./PostItem"

function MediumPosts() {
  
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const parser = new Parser()

    async function fetchPosts() {
      const url = '../mediumrss.xml'
      const feed = await parser.parseURL(url)
      extractPostInfo(feed)
      // console.log(feed)
    } 

    function extractPostInfo(feed){
      const newPosts = []
      feed.items.forEach(item => {
        // console.log(item)
        const title = item.title
        const date = item.pubDate

        const contentArray = item["content:encoded"].split(/\<(.*?)\>/)
        const img = contentArray.find(chunk => chunk.includes("img"))

        const subTitle = contentArray[4]
        const url = item.guid
        // console.log(contentArray)

        const newPost = {title, date, img, subTitle, url}
        newPosts.push(newPost)
      })
      setPosts(newPosts)
    }

    fetchPosts()

  }, [])

  const postItems = posts.map(post => <PostItem {...post} key={post.title}/>)

  return (
    <div className="posts-list">
      {postItems}
    </div>
  )
}

export default MediumPosts