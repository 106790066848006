import {NavLink} from "react-router-dom"

function Nav() {

  return (
    <nav>
  
      <h1>Diana J Liao</h1>

      <ul>
        <NavLink to="/about">
          <li>About</li>
        </NavLink>
        {/* <NavLink to="/resume">
          <li>Resume</li>
        </NavLink> */}
        <NavLink to="/projects">
          <li>Projects</li>
        </NavLink>
        <NavLink to="/posts">
          <li>Posts</li>
        </NavLink>
      </ul>
    </nav>
  )
}

export default Nav