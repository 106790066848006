


function PostItem(props){

  const {title, img, subTitle, date, url} = props
  // console.log(props)

  const imgUrl = img.split(/"(.*?)"/)[3]
  const imgAlt = img.split(/"(.*?)"/)[1]

  const handleClick = () => window.location = url

  return(
    <div onClick={handleClick} className="post-card">
      <img src={imgUrl} alt={imgAlt} />
      <div className="post-info">
        <a href={url}>
          <h5 className="post-title">{title}</h5>
        </a>
        <div className="post-subtitle">
          {subTitle}
        </div>
        <time>
          {date.slice(4,17)}
        </time>
      </div>
    </div>
  )
}

export default PostItem