
function ProjectItem({title, desc, image, tech, other}){
  
  return (
    <div className="project-card">
      <div className="project-img-container">
        <img src={image} alt={`screenshot of ${title}`} />
      </div>
      <div className="project-title">{title}</div>
      <div className="project-desc">{desc}</div>
      <div className="project-tech-list">
        {tech.map(name => {
          return <span className="tech" key={name}>{name}</span>
        })}
      </div>
      <div className="overlay">
        <div className="hover-info">

          Github: {other.githubFront ? <a href={other.githubFront} target="_blank">Frontend</a> : null} 
          {other.githubBack ? <a href= {other.githubBack} target="_blank">Backend</a> : null}
          {other.github ? <a href= {other.github} target="_blank">Repo</a> : null}
          <br/>
        
          Demo: {other.demo ?  <a href={other.demo} target="_blank">YouTube</a> : "none yet!"}
        </div>
      </div>
    </div>
  )

}

export default ProjectItem