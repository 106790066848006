
function SocialMedia () {

  return (
    <div className="social">
      <a href="https://www.linkedin.com/in/djliao/">LinkedIn </a> | <a href="https://github.com/DianaLiao">GitHub </a> | djliao(at)gmail.com
    </div>
  )
}

export default SocialMedia