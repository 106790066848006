import ProjectItem from "./ProjectItem"
import {projectList} from "../data/projectInfo.js"

function Projects() {

  // const projectList = [
  //   {
  //     title: "Pokématch 'em All",
  //     desc: "A memory card-matching game, with statistics and leaderboards",
  //     image: "../pokematch.png",
  //     tech: ["Ruby", "Ruby on Rails", "React", "JavaScript"]
  //   }
  // ]
  
  return (
    <div className="projects">
      <h2>Projects</h2>
      <p>
        This website is currently built with React, with the help of Bootstrap and an RSS parser. Here are some of my projects (more to be posted soon!):
      </p>
      <div className="project-list">
        {projectList.map(project => <ProjectItem {...project} key={project.title}/>)}
      </div>
    </div>
  )
}

export default Projects