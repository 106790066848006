// import DocViewer from "./DocViewer";


function Resume() {

  return (
    <div className="resume">

      <h2>Experience</h2>
      
      <div className="center pdf-link"><a href="../DianaLiaoResume.pdf">View</a> or <a href="../DianaLiaoResume.pdf" download>download</a> resume as a PDF or scroll below.
      </div>

      <iframe src="https://docs.google.com/document/d/e/2PACX-1vSvYGLJxeIyuJRsMSpvpBPKKcyBUkfGfhZRp18DMA2ivFN7MZs2XRd7HxNx3mag3Mz9-4Nd4MaxKIgs/pub?embedded=true"
      title="resume"
      className="resume-viewer"
      >

      </iframe>
    </div>
  )
}

export default Resume