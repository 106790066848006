
export const projectList = [
  {
    title: "Pokématch 'em All",
    desc: "Catch Pokémon with this memory card-matching game, fill out your Pokédex and top the leaderboards!",
    image: "../pokematch.png",
    tech: ["Ruby", "Ruby on Rails", "React", "JavaScript"],
    other: {
      githubFront: "https://github.com/DianaLiao/pokematch-frontend",
      githubBack: "https://github.com/DianaLiao/pokematch-backend",
      demo: "https://www.youtube.com/watch?v=76T--Kxscak"
    }
  },
  {
    title: "Sketchy",
    desc: "Let your creativity flow and jot down drawings to save in your galleries.",
    image: "../sketchy.png",
    tech: ["Ruby", "Ruby on Rails", "React", "JavaScript"],
    other: {
      githubFront: "https://github.com/DianaLiao/Sketchy-frontend",
      githubBack: "https://github.com/DianaLiao/Sketchy-backend"
    }
  },
//   {
//     title: "What the Fridge",
//     desc: "Catch Pokémon with this memory card-matching game, with statistics and leaderboards",
//     image: "../pokematch.png",
//     tech: ["Ruby", "Ruby on Rails", "React", "JavaScript"]
//   },
//   {
//     title: "Ham Ham Post",
//     desc: "Catch Pokémon with this memory card-matching game, with statistics and leaderboards",
//     image: "../pokematch.png",
//     tech: ["Ruby", "Ruby on Rails", "React", "JavaScript"]
//   },
//   {
//     title: "Hamstar Haven",
//     desc: "Catch Pokémon with this memory card-matching game, with statistics and leaderboards",
//     image: "../pokematch.png",
//     tech: ["Ruby", "Ruby on Rails", "React", "JavaScript"]
//   },
  
]
