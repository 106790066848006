
function About() {

  return (
    <div className="about">
      <div className="one">
        {/* <img src="../pixel-portrait.png" alt="pixelated selfie of dark haired woman"></img> */}
        <h2>Hi, I'm Diana.</h2>
      </div>
      <div className="two">
        <div className="desc-text">
          <p>I'm an aspiring software developer based out of Washington, DC. 👋🏻 </p>

          {/* <p>After working as a patent examiner, I decided to use my analytical skills to more directly help people: designing martial arts after-school programs, assisting in group therapy sessions and increasing administrative efficiency in a mental health practice, and most recently, finding my place in activism and advocacy here in the nation's capital. I am passionate about social justice as a whole, and my last role focused on increasing diversity, equity, and inclusion in academia and STEM education. </p> */}
          
          <p>The first code I wrote was in BASIC on my Commodore 64. My first program written of completely my own design was on my TI-83 calculator. These days, I work in React and Rails. But who knows about tomorrow!</p>
        </div>
      </div>
    </div>
  )
}

export default About

