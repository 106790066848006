import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import About from "./components/About";
import MediumPosts from "./components/MediumPosts";

// import Header from "./components/Header";
// import Home from "./components/Home";
import Nav from "./components/Nav";
import Projects from "./components/Projects";
import Resume from "./components/Resume";
import SocialMedia from "./components/SocialMedia";


function App() {
  return (
    <div className="App">
      <Router>
        {/* <Header /> */}
        <Nav />
        <main>
          <Switch>
            <Route path="/resume">
              <Resume />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/posts">
              <MediumPosts />
            </Route>
            <Route path="/">
              <About />
            </Route>
          </Switch>
        </main>
      </Router>
      <SocialMedia />
    </div>
  );
}

export default App;
